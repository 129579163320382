import {bootstrapModal} from "../lib/bootstrapModal";
import {buttonEnable, buttonProgress} from "../lib/buttonProgress";
import {Messages} from "../messages/Messages";
import {apiPost} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {isAjaxResponseOk, toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";
import {ContainerState} from "../dto/com.rico.sb2.entity.detail";

const messages = new Messages();

export class CancelContainer {
    private readonly containerId: number
    private readonly containerState: ContainerState

    constructor(containerId: number, containerState: ContainerState) {
        this.containerId = containerId
        this.containerState = containerState
    }

    private renderBody() {
        const messageCode = `CancelContainer.confirmBody.${this.containerState}`;
        return `<div class="text-center">${messages.get(messageCode)}</div>`;
    }

    execute(): Promise<ContainerState> {
        return new Promise((resolve, reject) => {
            apiPost(`${AppConfig.CP}/op/cancelContainer`, {container: this.containerId})
                .then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res.message))
                .then(newState => {
                    showToastInfo(messages.get('CancelContainer.completeNotice'))
                    resolve(newState)
                })
                .catch(error => {
                    toastFetchError(error);
                    reject();
                })
        })
    }

    showModal(completer?: Function) {
        const {modal, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
            title: messages.get(`CancelContainer.confirmNotice`, this.containerId),
            body: this.renderBody(),
            buttonOk: messages.get(`CancelContainer.confirmButton.${this.containerState}`)
        });
        modal.show();

        const ok = buttonOk!!;

        ok.addEventListener('click', () => {
            buttonOkClickStart();

            this.execute()
                .then(newState => {
                    modal.hide()
                    if (completer) completer(newState)
                })
                .catch(buttonOkClickFinish);
        })

    }
}