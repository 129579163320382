import {escapeHTML} from "../lib/escapeHTML";
import {bootstrapModal} from "../lib/bootstrapModal";
import {buttonEnable, buttonProgress} from "../lib/buttonProgress";
import {Messages} from "../messages/Messages";
import {toggleDisabled, toggleHidden} from "../lib/domFunctions";
import {fetchJsonForApiResponse, isAjaxResponseOk, toastFetchError} from "../lib/fetch";
import {AppConfig} from "../AppConfig";
import {apiPost} from "../lib/SecuredAjax";
import {showToastInfo} from "../lib/boostrapToast";
import {PositionSelectOption} from "../dto/com.rico.sb2.service.positions";

const messages = new Messages();

export class SendContainerToPosition {
	private readonly skipPosition: number | null
	private readonly container: number

	constructor(container: number, skipPosition: number | null) {
		this.skipPosition = skipPosition
		this.container = container
	}

	execute(position: number): Promise<void> {
		return new Promise((resolve, reject) => {
			apiPost(`${AppConfig.CP}/op/moveContainer`, {container: this.container, position})
				.then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res.message))
				.then(success => {
					if (!success) return Promise.reject(500)

					showToastInfo(messages.get('SendContainerToPosition.completeNotice'))
					resolve()
				})
				.catch(error => {
					toastFetchError(error);
					reject();
				})
		})
	}

	showModal(success?: Function) {
		const {modal, content, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
			title: messages.get('SendContainerToPosition.title', this.container),
			body: this.renderForm(),
			buttonOk: messages.get('button.send')
		});
		modal.show();

		const ok = buttonOk!!;
		const positionSelect = content.querySelector<HTMLSelectElement>('select[name="position"]')!!;

		function okToggle() {
			toggleDisabled(ok, !isFinite(parseInt(positionSelect.value)))
		}

		positionSelect.addEventListener('change', okToggle);
		okToggle()

		ok.addEventListener('click', () => {
			const position = parseInt(positionSelect.value);
			if (!position) return;

			buttonOkClickStart();

			this.execute(position)
				.then(() => {
					modal.hide()
					if (success) success()
				})
				.catch(buttonOkClickFinish)
		})

		function loadingStop() {
			content.querySelector('[data-bind="loading"]')?.remove()
			okToggle()
		}

		fetchJsonForApiResponse(`${AppConfig.CP}/op/listMoveContainerTargets?container=${this.container}`)
			.then((positions: PositionSelectOption[]) => {
				positions = positions.filter((p: any) => p.id != this.skipPosition)

				if (positions.length > 0) {
					positionSelect.innerHTML = positions
						.map((p: any) => `<option value="${p.id}">${p.id}: ${escapeHTML(p.label)}</option>`)
						.join("")
					toggleHidden(content.querySelector('[data-bind="select"]')!!, false)
				} else {
					toggleHidden(content.querySelector('[data-bind="emptyMessage"]')!!, false)
				}
			})
			.catch(toastFetchError)
			.then(loadingStop)
	}

	private renderForm(): string {
		return `
            <div class="text-center" data-bind="loading"><div class="lds-dual-ring"></div></div>        
            <div class="hidden text-center" data-bind="emptyMessage">${escapeHTML(messages.get('SendContainerToPosition.noAvailablePositions'))}</div>
            <div class="hidden" data-bind="select"><select name="position" class="form-select" required></select></div>
        `
	}
}