import {ContainerTypeInfo} from "./dto/com.rico.sb2.service.queue";

export const AppConfig = {
    CP: "",
    locale: "",

    roles: [],

    ContainerTypes: [] as ContainerTypeInfo[],
    ContainerTypeNamerConfig: {
        defaultName: '',
        names: {}
    },

    hideDashboardForm: {
        minWindowWidth: null,
        minWindowHeight: null,
    },

    showSendToStorage: false,

    trolleyLine: false,

    logged() {
        return this.roles.length > 0
    }
}